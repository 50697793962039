import React from "react";

const Team = () => {
  return (
    <section
      id="team"
      className="team section section-bg dark-background"
      style={{ padding: "60px 0", backgroundColor: "#333", color: "white" }}
    >
      {/* Section Title */}
      <div
        className="container section-title aos-init aos-animate"
        data-aos="fade-up"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        <h2 style={{ fontSize: "2.5rem", fontWeight: 700 }}>Team</h2>
        <p style={{ fontSize: "1.2rem", marginTop: 10 }}>
          We are a team from <br /> Buni Hub - Hub of Hubs | SA Innovation
          Summit
        </p>
      </div>
      {/* End Section Title */}
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div
              className="team-member"
              style={{
                backgroundColor: "#444",
                borderRadius: 10,
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Picture7.png"
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </div>
              <div
                className="member-info"
                style={{ padding: 20, textAlign: "center" }}
              >
                <h4 style={{ fontSize: "1.25rem", fontWeight: 700 }}>
                  Patience Abraham
                </h4>
                <span style={{ fontSize: "1rem" }}>Team Leader</span>
              </div>
            </div>
          </div>
          {/* End Team Member */}
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div
              className="team-member"
              style={{
                backgroundColor: "#444",
                borderRadius: 10,
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Picture8.png"
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </div>
              <div
                className="member-info"
                style={{ padding: 20, textAlign: "center" }}
              >
                <h4 style={{ fontSize: "1.25rem", fontWeight: 700 }}>
                  Buntu Majaja
                </h4>
                <span style={{ fontSize: "1rem" }}>
                  Incubation and Innovation Management Specialist
                </span>
              </div>
            </div>
          </div>
          {/* End Team Member */}
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <div
              className="team-member"
              style={{
                backgroundColor: "#444",
                borderRadius: 10,
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Picture10.png"
                  className="img-fluid"
                  alt =""
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </div>
              <div
                className="member-info"
                style={{ padding: 20, textAlign: "center" }}
              >
                <span style={{ fontSize: "1rem" }}>
                  Key Expert 2 - Communication and Strategic Partnerships
                </span>
              </div>
            </div>
          </div>
          {/* End Team Member */}
        </div>
      </div>
    </section>
  );
};

export default Team;
