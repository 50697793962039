import React from "react";

const About = () => {
  return (
    <section id="about" className="about section section-bg dark-background">
      <div className="container position-relative">
        <div className="row gy-5">
          <div
           className="content col-xl-5 d-flex flex-column aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <h3>Introduction </h3>
            <p>
              The Incubation Leadership and Innovation Management Programme for
              Universities in EAC project is designed to underscore the pivotal
              role of universities in leading the digital transformation and
              capacity building in the region. The project seeks to implement
              and conduct capacity building programmes based on the specific
              needs and existing gaps experienced by the universities on
              operating an incubation centre and provide an enhanced knowledge
              on the design and implementation of innovation management
              strategies that support technology and innovation transfer between
              academia and industry while assisting universities develop
              practical scenarios and access pragmatic tools and resources
              needed to establish sustainable business incubation models and
              operate profitable and sustainable incubation centre that drive
              innovations to scale from academia to industry.
            </p>
            <a
              href="/"
              className="about-btn align-self-center align-self-xl-start"
            >
              <span>About us</span> <i className="bi bi-chevron-right" />
            </a>
          </div>
          <div className="col-xl-7 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
            <div className="row gy-4">
              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-briefcase" />
                <h4>
                  <a href className="stretched-link">
                    Key Objective &amp; Target
                  </a>
                </h4>
                <p>
                  The project is part of the Digital Skills for an Innovative
                  East African Industry (dskillz@EA); a technical development
                  cooperation between the EAC and Germany implemented by the
                  Inter-University Council of East Africa (IUCEA) and the
                  Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ).
                  The project collaborates closely with the private sector and a
                  consortium of academic institutions from both the EAC and
                  Germany. It is anchored as a module in the regional programme
                  "Education in the East African Community (EAC)", which
                  supports employment perspectives for youth in the EAC.
                </p>
              </div>
              {/* Icon-Box */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
