import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer dark-background">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 footer-about">
            <a href="index.html" className="logo d-flex align-items-center">
              <span className="sitename">ULead</span>
            </a>
          </div>
        </div>
      </div>
      {/* Contact Information Section */}
      <div className="container text-center mt-4" style={{ color: "white" }}>
        <p>For more information, please contact:</p>
        <p>
          <strong>Project Lead:</strong> Ms. Patience Abraham
          <br />
          <strong>Phone:</strong> +255786599100
          <br />
          <strong>Email:</strong>{" "}
          <a
            href="mailto:patience.karua@costech.or.tz"
            style={{ color: "#e74c3c", textDecoration: "none" }}
          >
            patience.karua@costech.or.tz
          </a>
        </p>
      </div>
      {/* Copyright Section */}
      <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>{" "}
          <strong className="px-1 sitename">ULead</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          {/* All the links in the footer should remain intact. */}
          {/* You can delete the links only if you've purchased the pro version. */}
          {/* Licensing information: https://bootstrapmade.com/license/ */}
          {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
          Designed by{" "}
          <a href="https://www.linkedin.com/in/emmanuel-jeremiah-12b25b285/">
            BuniHub
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
