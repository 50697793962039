import React from "react";
import { Link } from "react-scroll";
import './Header.css';  // Ensure this imports your CSS file

const Header = () => {
  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <h1 className="sitename">ULead</h1>
          <span>.</span>
        </a>
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link
                to="hero"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
                className="active"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                Team
              </Link>
            </li>
            <i className="mobile-nav-toggle d-xl-none bi bi-list" />
          </ul>
        </nav>
        <Link
          to="about"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-70}
          className="btn-getstarted"
        >
          Get Started
        </Link>
      </div>
    </header>
  );
};

export default Header;
