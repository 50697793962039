import React from "react";

const Tabs = () => {
  return (
    <section
      id="tabs"
      className="tabs section"
      style={{ backgroundColor: "#f9f9f9", padding: "60px 0" }}
    >
      <div className="container aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
        <div
          className="tab-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: 8,
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
            padding: 30,
          }}
        >
          <div className="tab-pane fade active show" id="tabs-tab-1">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                <h3 style={{ fontSize: 28, fontWeight: 700, color: "#333" }}>
                  Expected Key Outcome
                </h3>
                <p
                  className="fst-italic"
                  style={{ fontSize: 16, color: "#555" }}
                >
                  Among the key outcomes expected in the implementation of the
                  programme are, but not limited to:
                </p>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li style={{ marginBottom: 15 }}>
                    <i
                      className="bi bi-check2-all"
                      style={{ color: "#ff5823", marginRight: 10 }}
                    />
                    <span style={{ fontSize: 16, color: "#333" }}>
                      {" "}
                      Improved management and commercialisation of Intellectual
                      Property in Higher Education institutions.
                    </span>
                  </li>
                  <li style={{ marginBottom: 15 }}>
                    <i
                      className="bi bi-check2-all"
                      style={{ color: "#ff5823", marginRight: 10 }}
                    />
                    <span style={{ fontSize: 16, color: "#333" }}>
                      {" "}
                      Increased awareness on creation of appropriate technology
                      transfer environment including appropriate frameworks and
                      policies. .
                    </span>
                  </li>
                  <li style={{ marginBottom: 15 }}>
                    <i
                      className="bi bi-check2-all"
                      style={{ color: "#ff5823", marginRight: 10 }}
                    />
                    <span style={{ fontSize: 16, color: "#333" }}>
                      {" "}
                      Strengthened academia-innovation ecosystem collaborations
                      through well developed and effective university research
                      and technology transfers offices that support programming
                      in incubation management.
                    </span>
                  </li>
                  <li style={{ marginBottom: 15 }}>
                    <i
                      className="bi bi-check2-all"
                      style={{ color: "#ff5823", marginRight: 10 }}
                    />
                    <span style={{ fontSize: 16, color: "#333" }}>
                      {" "}
                      Strengthened capacity of university staff responsible for
                      technology transfer units, intellectual Property
                      management offices on incubation leadership and innovation
                      management.
                    </span>
                  </li>
                  <li style={{ marginBottom: 15 }}>
                    <i
                      className="bi bi-check2-all"
                      style={{ color: "#ff5823", marginRight: 10 }}
                    />
                    <span style={{ fontSize: 16, color: "#333" }}>
                      {" "}
                      Creation of a pool of trained and experienced professional
                      on innovation management and research commercialization in
                      higher education institutions.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 text-center">
                <img
                  src="assets/img/working-1.jpg"
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: 8, maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
          {/* End Tab Content Item */}
        </div>
      </div>
    </section>
  );
};

export default Tabs;
