import React from "react";

const Pricing = () => {
  return (
    <section
      id="pricing"
      className="pricing section section-bg dark-background"
    >
      {/* Section Title */}
      <div className="container section-title aos-init aos-animate" data-aos="fade-up">
        <h2>Key Focus Area</h2>
        <p>The following are the key focus areas </p>
      </div>
      {/* End Section Title */}
      <div className="container">
        <div className="row g-4 g-lg-0">
          <div className="col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay={100}>
            <div className="pricing-item">
              <img
                src="assets/img/Picture11.png"
                alt=""
                className="img-fluid mb-3"
              />
              <h3>Incubation Leadership Management</h3>
            </div>
          </div>
          {/* End Pricing Item */}
          <div
            className="col-lg-4 featured aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-delay={200}
          >
            <div className="pricing-item">
              <img
                src="assets/img/Picture12.png"
                alt=""
                className="img-fluid mb-3"
              />
              <h3>Innovation Management Training</h3>
            </div>
          </div>
          {/* End Pricing Item */}
          <div className="col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay={300}>
            <div className="pricing-item">
              <img
                src="assets/img/Picture13.png"
                alt=""
                className="img-fluid mb-3"
              />
              <h3>Digital Skills Enhancement</h3>
            </div>
          </div>
          {/* End Pricing Item */}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
